import useRequest, { CommonResponse, useRequestOptions } from "./../useRequest";
import { computed } from "vue";

export type useGatewayListParams = {
  q?: string;
  page?: number;
  limit?: number;
};

const useGatewayList = (
  getParams: () => useGatewayListParams = () => ({ page: 1, limit: 20 }),
  options?: useRequestOptions
) => {
  const { data, ...other } = useRequest<CommonResponse<[]>>(
    "/app/entity/gateway",
    getParams,
    options
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data;
  });

  return {
    data: enhancedData,
    ...other
  };
};

export default useGatewayList;
